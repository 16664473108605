<button
    class="editor-btn height-small radius-25 border-0 primary icon-only"
    [matMenuTriggerFor]="menu"
    aria-label="menu"
>
    <span class="nitroicon-courses editor-btn-icon header-icon"></span>
</button>
<a class="logo" [routerLink]="['/courses-list']">
    <img *ngIf="!questionBankActive" src="./assets/images/nitro_learning_logo_courze_cloud_black.svg" alt="" />
    <img *ngIf="questionBankActive" class="questionbank-logo" src="./assets/images/c&cquestionbank.svg" alt="" />
</a>
<mat-menu #menu="matMenu">
    <a mat-menu-item [href]="'/exam-admin/'">
        <img src="./assets/images/cc_exams_logo.svg" alt="" />
    </a>
    <a *ngIf="!questionBankActive" mat-menu-item [routerLink]="['/', 'course', 'question-bank']">
        <img class="questionbank-logo" src="./assets/images/c&cquestionbank.svg" [alt]="'QUESTION_BANK.PAGE_TITLE' | translate" />
    </a>
    <a *ngIf="questionBankActive" mat-menu-item [routerLink]="['/courses-list']">
        <img class="cc-logo" src="./assets/images/nitro_learning_logo_courze_cloud_black.svg" [alt]="'QUESTION_BANK.PAGE_TITLE' | translate" />
    </a>
</mat-menu>
