import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-application-selector',
    templateUrl: './application-selector.component.html',
    styleUrls: ['./application-selector.component.scss'],
})
export class ApplicationSelectorComponent {
    questionBankActive: boolean;

    constructor(private router: Router) {
        this.router.events.subscribe(() => {
            this.questionBankActive = window.location.href.includes('question-bank');
        });

        this.questionBankActive = window.location.href.includes('question-bank');
    }
}
